/*mobile*/


.open{
    overflow: hidden;
}
.nav{
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: #222831;
    height: 6vh;
    position: sticky;
    top: 0;
    z-index: 10;
}

.hamburger{
    height: 30px;
    width: 30px;
    z-index: 10;
    cursor: pointer;
    margin: auto 1.5em auto auto;
}

.line{
    height: 3px;
    width: 30px;
    background-color: #FFD369;
    margin: 5px;

}

.nav-links{
    display: none;
}

.nav-links-toggled{
    list-style: none;
    width: 100%;
    height: 110vh;
    position: absolute;
    top: -2.5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background-color: #222831;
    pointer-events: all;
    z-index: 10;
    margin: 0;
    padding: 0;
}

.isActive{
    font-weight: bold;
    color:#FFD369;
}

a{
    text-decoration: none;
    font-family: nunito;
    font-size: 1.5em;
    color: white;
    z-index: 5;
}

@media only screen and (min-width: 768px){
    .hamburger{
        width: 45px;
        height: 45px;
    }

    .line{
        width: 45px;
        height: 5px;
        margin: 6px;
    }
}




/*Desktop*/
@media only screen and (min-width: 1024px){
.hamburger{
    display:none;
}

.nav-links-toggled{
    width: 25%;
    height: 45px;
    margin-top: 35px;
    margin-left: 63%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 45px;
    z-index: 10;
    flex-direction: row;
    background-color: transparent;
    transition: none;
}

.nav{
    z-index: 11;
    display: flex;
    justify-content: space-between;
    width: 100%;
}



.nav-links{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    z-index: 10;
    padding: 0;
}

.isActive{
    font-weight: bold;
    color: #FFD369;
}

a{
    text-decoration: none;
    font-family: nunito;
    font-size: 1.5em;
    color: white;
    z-index: 5;
}
}