* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

p,
h4 {
    font-family: nunito;
}

.about-hero {
    height: 44vh;
    display: flex;
    flex-direction: column;
    background: #393E46;
}

.about-hero>img {
    margin: auto;
}

.about-section-title {
    display: none;
}

.about-hero-stmt {
    margin: 1.5em;
    font-size: 1.2em;
}

.me {
    width: 250px;
    background-color: black;
    margin: 0 auto;
}

.title {
    color: #FFD369;
    font-size: 1.8em;
    margin-bottom: 0.5em;
}

.about-me {
    padding: 1.5em;
    padding-bottom: 5em;
    text-align: center;
    background: #222831;
    height: 66vh;
}

.about-me>p {
    color: white;
}

.unrelated-facts {
    margin: 20px 10px 20px 10px;
}

@media only screen and (min-width: 768px) {
    .me {
        width: 350px;
    }
    .title {
        font-size: 2.4em;
    }
    .about-me {
        height: 60vh;
        padding-top: 6rem;
    }
    .about-me>p {
        width: 25em;
        margin: 0 auto;
        font-size: 1.3em;
    }
}

@media only screen and (min-width: 1024px) {
    .me {
        width: 375px;
    }
    .about-hero {
        height: 50vh;
        align-items: center;
        margin: 0;
    }
    .about-section-title {
        margin-left: 30px;
        font-size: 1.5em;
    }
    .about-hero-stmt {
        font-size: 1.6em;
        margin-left: 30px;
        width: 40%;
    }
    .title {
        font-size: 1.5em;
    }
    .unrelated-facts {
        margin: 50px 30px;
    }
    p {
        font-size: 1.2em;
    }
}

@media only screen and (min-width: 1300px) {
    .me {
        width: 400px;
    }
}