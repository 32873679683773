form{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    background: #393E46;
}

.page-title{
    font-size: 1.2em;
    color: #FFD369;
    margin: 1em auto;
}

input{
    font-family: nunito;
    height: 30px;
    margin: 15px 30px;
}


.name-container{
    display: flex;
    flex-direction: column;
}

.email-container{
    display: flex;
    flex-direction: column;
}

.message-container{
    display: flex;
    flex-direction: column;
}


textarea{
    margin: 15px 30px;
    height: 150px;
}

.form-submit{
    width: 100px;
    height: 30px;
    margin: 30px auto;
    letter-spacing: .3em;
    font-weight: bold;
    color: #393E46;
    background-color: #FFD369;
    border-color: #FFD369;
}

@media only screen and (min-width: 768px){
    form{
        width: 100%;

    }

    .page-title{
        font-size: 1.6em;
    }

    input{
        width: 60%;
        margin: 25px auto;
    }

    textarea{
        width: 60%;
        margin: 25px auto;
    }

    .name-container{
        width: 100%;
    }

    .form-submit{
        width: 150px;
        height: 60px;
        font-size: 1.3em;
    }
}

@media only screen and (min-width: 1024px){
    .page-title{
        font-size: 2em;
    }

    input{
        width: 40%;
    }

    textarea{
        width: 40%;
    }
}

